import * as React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { ApplicationContextProvider } from './ApplicationContextProvider';
import { AsyncCreditCardModal } from './components';
import { getDefaultModalProps } from './components/overlayUtils';
import { AsyncConvertToPaid } from './ConvertToPaid';
import {
	AsyncCancelAccount,
	AsyncEditBillingInfo,
	AsyncManagePlan,
	AsyncTrialInformationBanner,
} from './EditBilling';
import { FeatureFlag } from './featureFlags';
import {
	showCancelAccount,
	showConvertToPaid,
	showEditBillingInfo,
	showManagePlan,
	showReactivateAccount,
	showReceipts,
	showTrialBanner,
} from './permissions';
import { AsyncReactivatePage } from './ReactivateAccount';
import { AsyncReceiptsPage } from './Receipts';
import {
	AdminBillingRoute,
	ConvertFreeTrialRoute,
	EditBillingInfoRoute,
	ManagePlanRoute,
	ReactivateRoute,
	ReceiptsRoute,
} from './routes';
import { billingPiletExtensionSlots } from './sdk';
import { billingPiletModals, PaymentGatewayProps } from './sdk/extensionTypes';
import { t } from './translate';

export function setup(piletApi: PiletApi) {
	const context: PiletUserContext = piletApi.sf.piletUserContext.get();
	const account = context.account;
	const accountId = context.account.Id;
	const userId = context.user.Id;
	const isFreeTrial = context.account.IsFreeTrial ?? true;
	const isFINRA = context.account.Preferences?.IsFINRA ?? false;
	const isVDR = (context.account.PlanTrack ?? '') === 'VDR';
	const isPlannedBillingDowntimeEnabled = getFeatureFlagValue(
		FeatureFlag.PlannedBillingDowntime,
		false
	);

	if (showConvertToPaid(context) || showEditBillingInfo(context)) {
		piletApi.registerModal<PaymentGatewayProps>(
			billingPiletModals.creditCardModal,
			({ completeInteraction, cancelInteraction, updateModalProps, options }) => (
				<AsyncCreditCardModal
					completeInteraction={completeInteraction}
					cancelInteraction={cancelInteraction}
					updateModalProps={updateModalProps}
					{...options!}
				/>
			),
			{
				modalProps: {
					title: t('edit_billing_information.credit_card.payment_gateway_title'),
					...getDefaultModalProps(),
				},
			}
		);
	}

	if (accountId && userId && showManagePlan(context) && showTrialBanner(context)) {
		piletApi.registerExtension(billingPiletExtensionSlots.trialBanner, ({ params }) => (
			<AsyncTrialInformationBanner {...params} />
		));
	}

	if (!isPlannedBillingDowntimeEnabled) {
		if (accountId && userId && showManagePlan(context)) {
			piletApi.registerPage(ManagePlanRoute, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncManagePlan
						accountId={accountId}
						userId={userId}
						isFreeTrial={isFreeTrial}
						account={account}
						datePreferences={context.user.Preferences?.DateFormat}
						timePreferences={context.user.Preferences?.TimeFormat}
						isVDR={isVDR}
						isFINRA={isFINRA}
					/>
				</ApplicationContextProvider>
			));

			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: ManagePlanRoute,
				title: () => t('manage_plan.left_nav_button_text') ?? '...',
				weight: 200,
				parent: AdminBillingRoute,
			});
		}

		if (showCancelAccount(context)) {
			piletApi.registerExtension(billingPiletExtensionSlots.cancelAccount, () => {
				return (
					<ApplicationContextProvider piletApi={piletApi}>
						<AsyncCancelAccount account={account}></AsyncCancelAccount>
					</ApplicationContextProvider>
				);
			});
		}

		if (showConvertToPaid(context)) {
			piletApi.registerPage(`${ConvertFreeTrialRoute}/:orderId?/:successFlag?`, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncConvertToPaid />
				</ApplicationContextProvider>
			));

			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: ConvertFreeTrialRoute,
				title: () => t('convert_to_paid.left_nav_button_text') ?? '...',
				weight: 200,
				parent: AdminBillingRoute,
			});
		}

		if (showReactivateAccount(context)) {
			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: ReactivateRoute,
				title: () => t('reactivate.left_nav_button_text') ?? '...',
				weight: 200,
				parent: AdminBillingRoute,
			});
			piletApi.registerPage(ReactivateRoute, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncReactivatePage />
				</ApplicationContextProvider>
			));
		}

		if (accountId && userId && showEditBillingInfo(context)) {
			piletApi.registerPage(`${EditBillingInfoRoute}/:orderId?/:successFlag?`, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncEditBillingInfo />
				</ApplicationContextProvider>
			));

			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: EditBillingInfoRoute,
				title: () => t('edit_billing_information.left_nav_button_text') ?? '...',
				weight: 200,
				parent: AdminBillingRoute,
			});
		}

		if (showReceipts(context)) {
			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: ReceiptsRoute,
				title: () => t('receipts.left_nav_button_text') ?? '...',
				weight: 200,
				parent: AdminBillingRoute,
			});
			piletApi.registerPage(`${ReceiptsRoute}/:orderId?/:successFlag?`, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncReceiptsPage />
				</ApplicationContextProvider>
			));
		}
	}
}
